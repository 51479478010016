import React from 'react'
import { graphql } from 'gatsby'
import BannerBlock from 'components/blocks/BannerBlock'
import BodyBlock from 'components/blocks/BodyBlock'
import SingleImageBlock from 'components/blocks/SingleImageBlock'
import TextImageBlock from 'components/blocks/TextImageBlock'
import TitleBlock from 'components/blocks/TitleBlock'
import QuoteBlock from 'components/blocks/QuoteBlock'
import SingleImageFullBlock from 'components/blocks/SingleImageFullBlock'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function BrandStoryPage({ data }) {
  const { locale } = useLocalization()
  const page = data.strapiBrandStoryPage
  const {
    title,
    introBannerBlock,
    introBodyBlock,
    singleImageBlock,
    bodyBlock2,
    textImageBlock,
    titleBlock,
    textImageBlock2,
    quoteBlock,
    singleImageBlock2,
  } = page
  const customBanner = {
    image: introBannerBlock.image,
    textColor: introBannerBlock.textColor,
    // pretitle: tr('about', locale),
    title,
    alignH: 'center',
    alignV: 'top',
  }
  return (
    <div className='page-brand-story'>
      <BannerBlock content={customBanner} mobileBgBeige={true} />
      <BodyBlock content={introBodyBlock} isIntro={true} />
      <SingleImageBlock content={singleImageBlock} />
      <BodyBlock content={bodyBlock2} />
      <TextImageBlock content={textImageBlock} mobileImageFirst={true} />
      <TitleBlock content={titleBlock} />
      <TextImageBlock
        content={textImageBlock2}
        mobileImageFirst={true}
        isReversed={true}
      />
      {/* <QuoteBlock
        content={quoteBlock}
        isReversed={true}
        mobileImageFirst={true}
      /> */}
      <SingleImageFullBlock
        content={singleImageBlock2}
        objectPositionRight={true}
      />
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiBrandStoryPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      introBannerBlock: bannerImageOnlyBlock {
        textColor
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      introBodyBlock {
        title
        body
      }
      singleImageBlock {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      bodyBlock2 {
        title
        body
      }
      textImageBlock {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
        body
      }
      titleBlock {
        pretitle
        title
      }
      textImageBlock2 {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
        body
      }
      singleImageBlock2 {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

// quoteBlock {
//   quote
//   source
//   image {
//     localFile {
//       childImageSharp {
//         gatsbyImageData
//       }
//     }
//   }
// }
