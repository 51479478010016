import React from 'react'
import Inview from 'components/Inview'

export default function TitleBlock({ content }) {
  const { pretitle, title } = content
  return (
    <section className='title-block pad-v-standard'>
      <div className='container'>
        <Inview className='fade-in up'>
          <div className='border' />
          <div className='text'>
            {pretitle && <h3>{pretitle}</h3>}
            {title && <h1>{title}</h1>}
          </div>
          <div className='border' />
        </Inview>
      </div>
    </section>
  )
}
